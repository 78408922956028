import moment from "moment/moment";
import Utils from "./Utils";
import SeducaoApi from "./SeducaoApi";
import ToastService from "./ToastService";

export default class AutoAtendimentoImpressaoService {

    constructor() {

        this.seducaoApi = new SeducaoApi();
        this.toastService = new ToastService();
    }

    GerarImpressao(autoAtendimentoId,callBack) {

        if (autoAtendimentoId) {

            this.seducaoApi.get(`autoatendimento/${autoAtendimentoId}/impressao`)
                .then(response => {

                    if (response) {

                        const cupom = this._montarCupom(response);

                        callBack(cupom);

                        this.toastService.success("Impressão realizada com sucesso");
                    } else {
                        this.toastService.warning("Não foi encontrado o auto atendimento para impressão");
                    }

                })
                .catch(error => {

                    console.error(error);

                    this.toastService.error(error);

                });

        } else {

            this.toastService.warning("Informe o id para impressão");
        }
    }

    CarregarImprimirById(autoAtendimentoId) {

        if (autoAtendimentoId) {

            this.seducaoApi.get(`autoatendimento/${autoAtendimentoId}/impressao`)
                .then(response => {

                    if (response) {

                        this.Imprimir(response)

                        this.toastService.success("Impressão realizada com sucesso");
                    } else {
                        this.toastService.warning("Não foi encontrado o auto atendimento para impressão");
                    }

                })
                .catch(error => {

                    console.error(error);

                    this.toastService.error(error);

                });

        } else {

            this.toastService.warning("Informe o id para impressão");
        }
    }

    CarregarImprimirBySenhaDeHoje(senha) {

        if (senha) {

            this.seducaoApi.get(`autoatendimento/senha/${senha}/hoje/impressao`)
                .then(response => {

                    if (response) {

                        this.Imprimir(response)

                        this.toastService.success("Impressão realizada com sucesso");
                    } else {
                        this.toastService.warning("Não foi encontrado o auto atendimento para impressão");
                    }


                })
                .catch(error => {

                    console.error(error);

                    this.toastService.error(error);

                });

        } else {

            this.toastService.warning("Informe a senha para impressão");
        }

    }

    ImprimirSenha(impressao) {

        const encoder = new EscPosPrintEncoder();

        encoder.initialize();

        encoder.align('center');

        encoder.bold();

        encoder.fontSizeCabecalho(impressao);

        encoder.text(`Senha: ${impressao.senha}`);

        encoder.newline();

        encoder.fontSizePadrao(impressao);

        encoder.text("Sorveteria Sedução");

        encoder.newline();

        encoder.text(moment(impressao.createdAt).format('DD/MM/YYYY HH:mm:ss'));

        encoder.newline();
        encoder.newline();

        encoder.cut();

        return encoder.toString();

    }

    Imprimir(impressao) {

        var cupom = this._montarCupom(impressao);

        var S = "#Intent;scheme=rawbt;";

        var P = "package=ru.a402d.rawbtprinter;end;";

        var textEncoded = encodeURI(cupom);

        window.location.href = "intent:" + textEncoded + S + P;

    }

    _montarCupom(impressao) {

        if (!impressao.configuracao) {
            impressao.configuracao = {

                tamanhoFonte: 8,
                tamanhoFonteValor: 3,
                tamanhoFonteCabecalho: 36,
                tamanhoFonteObservacao: 1,
                tamanhoFonteAdicionais: 24,
                tamanhoFonteAvisoViagem: 26,
                textoCabecalho: "Sorveteria Sedução",
                textoRodape: "Obrigado pela preferência"
            }
        }

        const encoder = new EscPosPrintEncoder();

        encoder.initialize();

        encoder.align('center');

        encoder.bold();

        encoder.fontSizeCabecalho(impressao);

        encoder.text(`Senha: ${impressao.senha}`);

        encoder.unBold();

        encoder.newline();

        encoder.fontSizePadrao(impressao);

        encoder.textLine(impressao.configuracao.textoCabecalho);

        encoder.textLine(moment(impressao.dataHora).format('DD/MM/YYYY HH:mm:ss'));

        encoder.align('left');

        console.log(impressao);

        if (impressao.produtos) {


            impressao.produtos.forEach((produto) => {

                encoder.fontSizePadrao(impressao);

                encoder.textLineCenter('--------------------------------');

                encoder.fontSizeObservacao(impressao);

                encoder.textLine(`${produto.descricaoGrupo}`);

                encoder.fontSizeAdicionais(impressao);

                const valorTotal = parseFloat(produto.valorIngredientes) + parseFloat(produto.valor);
                const valor = Utils.toDecimal(produto.valor);

                encoder.textLine(`${produto.descricao} ${valor}`);

                if (produto.observacao) {

                    encoder.fontSizeObservacao(impressao);

                    encoder.italic();

                    encoder.textLineCenter("*******")

                    encoder.textLine(`${produto.observacao}`);

                    encoder.textLineCenter("*******")

                    encoder.unItalic();
                }

                encoder.fontSizePadrao(impressao);

                produto.passos.forEach((passo) => {

                    encoder.fontSizeObservacao(impressao);

                    encoder.textLine(`${passo.descricao}`);

                    encoder.fontSizePadrao(impressao);

                    this._textoIngredientes(encoder, passo.ingredientes);

                    encoder.newline();
                })

                encoder.fontSizeValor(impressao);

                encoder.bold();

                encoder.textLine(`Valor: R$${Utils.toDecimal(valorTotal)}`);

                encoder.unBold();

                encoder.fontSizePadrao(impressao);
            });

            if (impressao.paraViagem) {

                encoder.textLineCenter('--------------------------------');

                encoder.textLineCenter("*******")

                encoder.fontSizeAvisoViagem(impressao);

                encoder.textLineCenter(`PARA VIAGEM`);

                encoder.fontSizePadrao(impressao);

                encoder.textLineCenter("*******")

            }

            encoder.textLineCenter('--------------------------------');

            encoder.align('right');

            encoder.fontSizeAvisoViagem(impressao);

            encoder.bold();

            const valorTotal = Utils.toDecimal(impressao.valorTotal);

            encoder.text(`Total: R$${valorTotal}`);

        }

        encoder.fontSizePadrao(impressao);
        
        if (impressao.configuracao.textoRodape) {
            
            encoder.newline();

            encoder.newline();

            encoder.textLine(impressao.configuracao.textoRodape);

            encoder.newline();
        }

        if (impressao.configuracao.quebraDeLinhaAntesDoCorte) {
            for (let i = 0; i < impressao.configuracao.quebraDeLinhaAntesDoCorte; i++) {
                encoder.newline();
            }
        }

        encoder.cut();

        console.log(encoder.toString());

        return encoder.toString();
    }


    _textoIngredientes(encoder, ingredientes) {

        if (ingredientes.length > 0) {

            ingredientes.forEach((ingrediente) => {

                const valor = Utils.toDecimal(ingrediente.valor);

                encoder.textLine(`${ingrediente.descricao} +R$${valor}`);

            });
        }
    }

    _ingredientesNaPosicao(autoAtendimento, posicao) {
        const ingredientesDaPosicao = {
            contem: false,
            coberturas: [],
            adicionais: [],
            sabores: []
        };

        autoAtendimento.produtos.forEach((produto) => {

            const coberturasDeBaixo = produto.coberturas.filter((cobertura) => cobertura.posicao === posicao);
            const adicionaisDeBaixo = produto.adicionais.filter((adicional) => adicional.posicao === posicao);
            const saboresDeBaixo = produto.sabores.filter((sabor) => sabor.posicao === posicao);

            ingredientesDaPosicao.coberturas = coberturasDeBaixo;

            ingredientesDaPosicao.adicionais = adicionaisDeBaixo;

            ingredientesDaPosicao.sabores = saboresDeBaixo;
        });

        ingredientesDaPosicao.contem = ingredientesDaPosicao.coberturas.length > 0
            || ingredientesDaPosicao.adicionais.length > 0
            || ingredientesDaPosicao.sabores.length > 0;


        return ingredientesDaPosicao;
    }
}

class EscPosPrintEncoder {

    constructor() {

        this._buffer = [];

    }

    initialize() {

        this._buffer.push(0x1B);

        this._buffer.push(0x40);
    }

    align(align) {

        this._currentAlign = align;

        this._buffer.push(0x1B);

        this._buffer.push(0x61);

        this._buffer.push(align === 'left' ? 0x00 : align === 'center' ? 0x01 : 0x02);

    }

    bold(enabled = true) {

        this._buffer.push(0x1B);

        this._buffer.push(0x45);

        this._buffer.push(enabled ? 0x01 : 0x00);

    }

    unBold() {

        this.bold(false);
    }

    text(text) {

        text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        for (let i = 0; i < text.length; i++) {

            this._buffer.push(text.charCodeAt(i));

        }
    }

    textLine(text) {

        this.text(text);

        this.newline();
    }

    textLineCenter(text) {

        const backToAlign = this._currentAlign;

        this.align('center');

        this.textLine(text);

        this.align(backToAlign);
    }

    newline() {

        this._buffer.push(0x0A);
    }

    cut() {

        this._buffer.push(0x1D);

        this._buffer.push(0x56);

        this._buffer.push(0x00);

    }

    doubleWidth(enabled = true) {

        this._buffer.push(0x1B);

        this._buffer.push(0x57);

        this._buffer.push(enabled ? 0x01 : 0x00);

    }

    height(height) {

        this._buffer.push(0x1D);

        this._buffer.push(0x68);

        this._buffer.push(height);

    }

    fontSizeCabecalho(impressao) {
        this.fontSize(impressao.configuracao.tamanhoFonteCabecalho);
    }

    fontSizePadrao(impressao) {
        this.fontSize(impressao.configuracao.tamanhoFonte);
    }

    fontSizeValor(impressao) {
        this.fontSize(impressao.configuracao.tamanhoFonteValor);
    }

    fontSizeObservacao(impressao) {
        this.fontSize(impressao.configuracao.tamanhoFonteObservacao);
    }

    fontSizeAdicionais(impressao) {
        this.fontSize(impressao.configuracao.tamanhoFonteAdicionais);
    }

    fontSizeAvisoViagem(impressao) {
        this.fontSize(impressao.configuracao.tamanhoFonteAvisoViagem);
    }

    fontSize(size) {

        this._buffer.push(0x1D);

        this._buffer.push(0x21);

        this._buffer.push(size);

    }

    italic(enabled = true) {

        this._buffer.push(0x1B);

        this._buffer.push(0x34);

        this._buffer.push(enabled ? 0x01 : 0x00);

    }

    unItalic() {

        this.italic(false);

    }

    build() {

        return this._buffer;

    }

    toString() {
        return this._buffer.map((byte) => {

            return String.fromCharCode(byte);

        }).join('');

    }
}
