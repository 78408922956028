import { Link, useNavigate } from "react-router-dom";
import BotaoSacola from "./BotaoSacola";
import { useEffect, useState } from "react";
import AutoAtendimentoService from "../services/AutoAtendimentoService";
import UpdateService from "../services/UpdateService";

const PageHeaderLoja = ({ children, backButtonPress, marginBottom }) => {

    const navigate = useNavigate();

    const [mostrarBotaosacola, setShowButton] = useState(false);

    useEffect(() => {

        new UpdateService().validateVersion();

        const sacola = new AutoAtendimentoService().getSacola();

        const pages = ["sacola", "senha"];

        const isNotInPage = !pages.some(page => window.location.pathname.includes(page));

        if (sacola.valorTotal > 0 && isNotInPage)
            setShowButton(true);
        else
            setShowButton(false);

    }, []);

    const refreshPage = () => {

        window.location.reload();
    }

    return (
        <div style={{ marginBottom: 80 }}>
            {mostrarBotaosacola && <BotaoSacola />}
            <nav className="navbar navbar-expand-lg fixed-top bg-palleta-rosa">
                <div className="container-fluid">
                    <Link to={"/"} className="navbar-brand p-2" href="#">
                        <img height={40} src="/images/logoBranca.png" alt="logo" />
                    </Link>
                    <button className="btn text-light">
                        <i className="bi bi-person-circle"></i> Entrar / Cadastrar
                    </button>
                </div>
            </nav>
        </div>
    )
}

export default PageHeaderLoja;