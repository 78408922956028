import Utils from "../../../services/Utils";
import { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { TipoIngrediente } from "../../../services/MontagemService";
import AutoAtendimentoService from "../../../services/AutoAtendimentoService";
import { IconImage } from "../../botoes/BotaoImagem";
import ToastService from "../../../services/ToastService";
import { useNavigate } from "react-router-dom";

const ConcluirMontagem = ({ produtoCompleto, onPassoClick }) => {

    const autoAtendimentoService = new AutoAtendimentoService();

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const textAreaObservacao = useRef(null);

    const [observacao, setObservacao] = useState("");

    const [pedido, setPedido] = useState(null);

    const [passos, setPassos] = useState([]);

    const [observacaoDigitada, setObservacaoDigitada] = useState("");

    const [produtoSemOpcoes, setProdutoSemOpcoes] = useState(false);

    const [concluindo, setConcluindo] = useState(false);


    useState(() => {

        setPassos(produtoCompleto.passos);

        setPedido(produtoCompleto.pedido);

        setProdutoSemOpcoes(produtoCompleto.passos.length === 0);

    }, []);

    const adicionarAosacola = () => {

        if (concluindo) return;

        setConcluindo(true);

        pedido.observacao = observacao;

        autoAtendimentoService.adicionarPedidoNaSacola(pedido);

        new ToastService().success(autoAtendimentoService.fraseComemorativaAleatoria());

        navigate("/sacola");
    }

    const confirmarObservacao = () => {

        setObservacao(observacaoDigitada);

        setShowModal(false);
    }

    return (pedido &&
        <div className="container">
            <div className="mb-3" >
                {
                    produtoSemOpcoes ?
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }} className="text-secondary text-center">
                                <span style={{ fontSize: "2.5em" }}>
                                    {pedido.produto.descricao}
                                </span>
                            </div>
                            <CardObservacao onChange={(obs) => setObservacao(obs)} observacao={observacao} buttonClick={() => setShowModal(true)} />
                        </div>
                        :

                        <div>
                            <div className="row">
                                {
                                    passos.map((passo, index) => {
                                        return <div key={index} className="col-md-4" onClick={() => onPassoClick(passo)}>
                                            <ResumoIngredientes pedido={pedido} passo={passo} />
                                        </div>
                                    })
                                }
                            </div>
                            <CardObservacao onChange={(obs) => setObservacao(obs)} observacao={observacao} buttonClick={() => setShowModal(true)} />
                        </div>
                }
            </div>
            <div >
                {
                    concluindo ?
                        <button className="btn btn-success btn-lg col-12" type="button" disabled style={{ marginTop: "" }}>
                            <span style={{ fontSize: "2.5em" }}>
                                <span style={{ fontSize: "2.5em" }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {" "}
                                Finalizando...
                            </span>
                        </button>
                        : <button onClick={() => adicionarAosacola()} className="btn btn-success btn-lg col-12" style={{ marginTop: "" }} >
                            <span style={{ fontSize: "1.em" }}>
                                <i className="bi bi-bag-plus"></i> Jogar na sacola
                            </span>
                        </button>
                }
            </div>
        </div >
    )
}

const CardObservacao = ({ onChange, observacao, buttonClick }) => {

    const [observacaoDigitada, setObservacaoDigitada] = useState("");

    const observacaoHancleChange = (e) => {
        setObservacaoDigitada(e.target.value);
        onChange(e.target.value);
    }

    return (
        <div className="border shadow p-3">
            <div style={{ fontSize: "1em", fontWeight: "bold" }}>
                Alguma observação?
                <hr className="my-1" />
            </div>
            <div className="col-md-12">
                <textarea name="textAreaObservacao" value={observacaoDigitada} onChange={(e) => observacaoHancleChange(e.target.value)} placeholder="Digite aqui sua observação" className="form-control" rows={5}>
                </textarea>
            </div>
        </div>
    )
}

const ResumoIngredientes = ({ passo, pedido }) => {

    const [ingredientes, setIngredientes] = useState([])
    const [titulo, setTitulo] = useState("")

    useEffect(() => {

        setTitulo(passo.titulo)

        const sabores = pedido.sabores.filter(n => n.passo.ordem === passo.ordem)
        const adicionais = pedido.adicionais.filter(n => n.passo.ordem === passo.ordem)
        const coberturas = pedido.coberturas.filter(n => n.passo.ordem === passo.ordem)

        if (passo.tipo === TipoIngrediente.ADICIONAL) {
            setIngredientes(adicionais);
        } else if (passo.tipo === TipoIngrediente.SABOR) {
            setIngredientes(sabores);
        } else if (passo.tipo === TipoIngrediente.COBERTURA) {
            setIngredientes(coberturas);
        }
    }, [passo, pedido])


    return (
        ingredientes ?
            <div className="p-3 shadow border mb-3">
                <div className="col-md-12" style={{ fontSize: "1em" }}>
                    {titulo} <span className="float-end bg-warning rounded-circle badge badge-pill">{ingredientes.length}</span>
                    <hr className="my-1" />
                </div>
                <div style={{ maxHeight: "170px", overflow: "Auto" }}>
                    {
                        ingredientes.length > 0 ?

                            ingredientes.map((ingrediente, index) => {
                                return (
                                    <div className="mb-2 col-md-12" key={index}>
                                        <div style={{ fontStyle: "italic" }} className="text-dark">
                                            <div>
                                                <IconImage size={25} imagem={ingrediente.img} />
                                                {" "}
                                                <span style={{ fontSize: "0.8em" }}>
                                                    {ingrediente.nome}
                                                </span>
                                                {
                                                    ingrediente.preco > 0 &&
                                                    <span className="text-danger" style={{ fontSize: "0.7em" }}> (+ {Utils.toCoin(ingrediente.preco)})</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className="col-12">
                                <div className="btn col-md-12">
                                    <span style={{ fontSize: "1.3em" }} className="text-muted">Nada</span>
                                </div>
                            </div>
                    }
                </div>
            </div> : null
    )
}

export default ConcluirMontagem;

