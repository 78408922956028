import moment from "moment";

class Utils {

    constructor() {
        this.months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ]
    }

    static toCoin(value) {

        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    static toDecimal(value, decimalPlaces = 2) {

        return parseFloat(value).toFixed(decimalPlaces).replace('.', ',');
    }

    static isHoje(dataHora) {

        let data = new Date(dataHora);

        let hoje = new Date();

        return data.getDate() === hoje.getDate() && data.getMonth() === hoje.getMonth() && data.getFullYear() === hoje.getFullYear();
    }

    static getDataHoraAtual() {

        return moment().format("YYYY-MM-DD HH:mm:ss");
    }
}

export default Utils;