import { useEffect, useState } from 'react';
import RoutesBalcao from './components/RoutesBalcao';
import RoutesLoja from './components/RoutesLoja';
import Configuracao from './Configuracao';

function App() {

  const [isBalcao, setIsBalcao] = useState(Configuracao.isBalcao);

  useEffect(() => {

    setIsBalcao(true);

  }, [])

  return isBalcao ? (
    <RoutesBalcao />
  ) : (
    <RoutesLoja />
  )
}

export default App;
