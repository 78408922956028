import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AutoAtendimentoService from "../../services/AutoAtendimentoService";

const PageSenha = params => {

    let loaded = false;

    const autoAtendimentoService = new AutoAtendimentoService

    const navigate = useNavigate();

    const { senha } = useParams();

    const [valorContagemRegressiva, setContagemRegressiva] = useState(15);

    const iniciarContagemRegressiva = () => {

        let contagem = 15;

        contagemRegressiva(contagem);
    }

    const contagemRegressiva = (contagem) => {

        if (!window.location.href.includes("senha")) return;

        setTimeout(() => {

            var proximaContagem = --contagem;

            if (valorContagemRegressiva !== proximaContagem)
                setContagemRegressiva(proximaContagem);

            if (proximaContagem === 0) {

                navigate("/");

            } else {

                contagemRegressiva(proximaContagem);
            }

        }, 1000);

    }

    useState(() => {

        if (loaded) return;

        loaded = true;

        iniciarContagemRegressiva();

    }, []);


    const voltarParaHome = () => {

        window.location.href = "/";

    }

    return (
        <div className="bg-success text-light" style={{ height: 760, display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "space-evenly" }}>
            <div style={{ fontSize: "3.5em" }}>
                <i className="bi bi-cash-coin"></i> Para pagar, informe a senha abaixo no caixa!
            </div>
            <div style={{ fontSize: "18em" }}>
                <span style={{ fontSize: "0.2em" }}>
                    Sua senha é
                </span>
                {senha}
            </div>
            <button onClick={voltarParaHome} className="btn btn-success p-4 btn-lg col-4 offset-4">
                <i className="bi bi-arrow-left"></i> Fazer outro pedido ({valorContagemRegressiva})
            </button>
        </div>
    )

}

export default PageSenha; 