import { useEffect, useState } from "react";
import { BotaoImagemDark, BotaoImagemSelected } from "../../botoes/BotaoImagem";
import { PosicaoIngrediente } from "../../../services/MontagemService";
import Utils from "../../../services/Utils";
import ToastService from "../../../services/ToastService";

const SelecionarIngredientes = ({ passo }) => {

    const toast = new ToastService();

    const [passoAtual, setPassoAtual] = useState(null);
    const [ingredientes, setIngredientes] = useState(null);
    const [quandidadeMaxima, setQuantidadeMaxima] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [ingredientesSelecionados, setIngredientesSelecionados] = useState(null);
    const [parametros, setParametros] = useState(null);
    const [posicao, setPosicao] = useState(null);
    const [blinkClass, setBlinkClass] = useState("");

    useEffect(() => {

        setBlinkClass("div-blink");

        setParametros(passo);

        setIngredientes(passo.ingredientes);

        setQuantidadeMaxima(passo.quantidadeMaxima());

        setTitulo(passo.titulo());

        setPosicao(passo.posicao);

        setPassoAtual(passo);

        setIngredientesSelecionados(passo.ingredientesSelecionados);

    }, [passo]);

    const selecionarIngrediente = (ingrediente) => {

        let newIngredientes = [...ingredientes];

        let selecionados = parametros.addIngrediente(ingrediente);

        setIngredientes(newIngredientes);

        setQuantidadeMaxima(parametros.quantidadeMaxima());

        setIngredientesSelecionados(selecionados);

    };

    const removerIngrediente = (ingrediente) => {
        let newIngredientes = [...ingredientes];

        const selecionados = parametros.removeIngrediente(ingrediente);

        setIngredientes(newIngredientes);

        setQuantidadeMaxima(parametros.quantidadeMaxima());

        setIngredientesSelecionados(selecionados);
    };

    const handleClickIngrediente = (ingrediente) => {

        if (ingredientesSelecionados && ingredientesSelecionados.find((s) => s.id === ingrediente.id))
            removerIngrediente(ingrediente);
        else
            selecionarIngrediente(ingrediente);
    };

    const validarAvancar = () => {
        setBlinkClass(null);
        parametros.onConcluir(ingredientesSelecionados);
    };

    const selecionarIngredienteNovamente = (e, ingrediente) => {

        e.preventDefault();
        e.stopPropagation();

        let vezesQueSelecionou = ingredientesSelecionados.filter((s) => s.id === ingrediente.id).length;

        if (vezesQueSelecionou < 3) {
            selecionarIngrediente(ingrediente);
        } else {
            toast.warning("Você só pode repetir um ingrediente 3 vezes na mesma posição.");
        }
    }

    return (parametros &&
        <div className={`selecionar-ingredientes ${blinkClass}`}>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12" style={{ display: "flex" }}>
                            <div style={{ height: 70 }}>{titulo}</div>
                            {
                                <div className="text-secondary" style={{ fontSize: "2.5em", marginLeft: "auto" }} >
                                    {quandidadeMaxima}
                                </div>
                            }
                        </div>
                        <hr />

                        <div className={`row ${parametros.explicarPosicionamento ? "col-9" : null} mb-3`} style={{ height: "420px", overflow: "auto", scrollbarWidth: "none", }}  >
                            {ingredientes.map((ingrediente) => (
                                <div key={`${ingrediente}_ingrediente_${ingrediente.id}`} onContextMenu={(e) => selecionarIngredienteNovamente(e, ingrediente)} onClick={() => handleClickIngrediente(ingrediente)} className="col-4 mb-2" >
                                    {ingredientesSelecionados && ingredientesSelecionados.find((s) => s.id === ingrediente.id)
                                        ? (<BotaoImagemSelected height={150} imagem={ingrediente.img}>
                                            <DivIngredienteSelecionado selecionados={ingredientesSelecionados} ingrediente={ingrediente} />
                                        </BotaoImagemSelected>
                                        ) : (
                                            <BotaoImagemDark height={150} imagem={ingrediente.img}>
                                                <span className="text-light" style={{ fontSize: "1.6em" }} >
                                                    {ingrediente.nome}
                                                </span>
                                                {passoAtual.getInfoPrecoIngrediente(ingrediente)}
                                            </BotaoImagemDark>
                                        )}
                                </div>
                            ))}
                        </div>

                        {parametros.explicarPosicionamento && (
                            <div className="col-3">
                                <ExplicacaoPosicionamento posicao={posicao} />
                            </div>
                        )}

                        <div className="col-12">
                            <div className="col-12">
                                <button onClick={() => validarAvancar()} className="btn btn-primary btn-lg col-12">
                                    <span style={{ fontSize: "2.5em" }}>
                                        Próximo <i className="bi bi-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DivIngredienteSelecionado = ({ selecionados, ingrediente }) => {

    const [preco, setPreco] = useState(0);

    useEffect(() => {

        let preco = 0;

        selecionados.forEach((s) => {
            if (s.id === ingrediente.id) {
                preco += s.preco;
            }
        });

        setPreco(preco);

    }, [selecionados, ingrediente]);

    return (
        <div className="text-light " style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "column", }} >
            <div style={{ fontSize: "1.6em" }}>
                {
                    selecionados.filter((s) => s.id === ingrediente.id).length > 1 ?
                        `${selecionados.filter((s) => s.id === ingrediente.id).length} x ` : null
                }
                {ingrediente.nome}
            </div>
            {preco > 0
                ? <div style={{ fontSize: "1.0em" }}>+ {Utils.toCoin(preco)} </div>
                : null
            }
        </div>
    )
}

const ExplicacaoPosicionamento = ({ posicao }) => {

    const imageSourcePadrao = "/images/copoPosicionamento.png";

    const [image, setImage] = useState(null);

    const [styleInfoAdicional, setStyleInfoAdicional] = useState({});

    const [imageInterval, setImageInterval] = useState(null);

    var loaded = false;

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        var styleInfoAdicional = {
            position: "absolute",
            color: "grey",
            fontSize: "0.8em",
            transform: "rotate(-81deg)",
            marginTop: "-120px",
            marginLeft: "220px",
            transformOrigin: "bottom center",
        };

        let imageSourcePosicao = "/images/copoPosicionamentoEmBaixo.png";

        if (posicao === PosicaoIngrediente.CIMA) {
            imageSourcePosicao = "/images/copoPosicionamentoEmCima.png";
        }

        if (posicao === PosicaoIngrediente.MEIO) {
            imageSourcePosicao = "/images/copoPosicionamentoNoMeio.png";
        }

        setImage(imageSourcePosicao);


        setStyleInfoAdicional(styleInfoAdicional);

    }, [posicao]);

    return (
        <div className="col-12 mt-5">
            <img className="img-fluid ms-3" src={image} alt="Imagem explicativa" />
            <div style={styleInfoAdicional}>
                Imagem meramente ilustrativa
            </div>
        </div>
    );
};

const LabelInfoCobranca = ({ ingrediente, parametros }) => {
    if (ingrediente.cobrarAposLimiteDoProduto)
        return (
            <div style={{ fontSize: "0.6em" }}>
                {parametros.mensagemCobrancaAposGratuitos()}
            </div>
        );

    if (ingrediente.quantidadeCobrar > 0)
        return (
            <div style={{ fontSize: "0.6em" }}>
                {parametros.mensagemCobrancaAposLimite(ingrediente.quantidadeCobrar)}
            </div>
        );
};

export default SelecionarIngredientes;
