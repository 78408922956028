import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SeducaoApi from "../../services/SeducaoApi";
import Utils from "../../services/Utils";
import PageHeader from "../../components/PageHeader";
import { BotaoImagemDark } from "../../components/botoes/BotaoImagem";
import AutoAtendimentoService from "../../services/AutoAtendimentoService";

const PageGrupo = params => {

    let loaded = false;

    const navigate = useNavigate();

    const { id } = useParams();

    const [grupo, setGrupo] = useState(null)
    const [produtos, setProdutos] = useState([])

    const seducaoApi = new SeducaoApi();

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        new AutoAtendimentoService().setProdutoSelecionado(null);

        seducaoApi.get(`grupos/${id}`).then(response => {

            setGrupo(response);
        })

        seducaoApi.get(`produtos?idGrupo=${id}`).then(produtos => {

            produtos.sort((a, b) => {
                if (a.preco < b.preco) {
                    return -1;
                }
                if (a.preco > b.preco) {
                    return 1;
                }
                return 0;
            })

            setProdutos(produtos);
        })

    }, []);

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                {produtos.filter(n => n.disponivel > 0).map(produto => {
                    return (
                        <div key={produto.id} onContextMenu={() => navigate(`/produto/${produto.id}`)} onClick={() => navigate(`/montar/${produto.id}`)} className="text-light mb-2 col-md-4">
                            <BotaoImagemDark imagem={produto.img} height={300}>
                                <div className="p-3 text-light">
                                    {
                                        produto.preco > 0
                                            ? <div>
                                                <div className="text-center" style={{ fontSize: "1.3em" }}>
                                                    {produto.nome}
                                                </div>
                                                <div className="text-center" >
                                                    <div style={{ fontSize: "2.8em" }}>{Utils.toCoin(produto.preco)}</div>
                                                </div>
                                            </div>
                                            : <div className="text-center" >
                                                <div style={{ fontSize: "2.8em" }}>{produto.nome}</div>
                                            </div>
                                    }

                                    <div className="text-center" style={{ fontSize: "0.9em" }}>
                                        {produto.descricao}
                                    </div>
                                </div>
                            </BotaoImagemDark>
                        </div>
                    )
                })}
            </div>
        </div >
    )

}

export default PageGrupo; 