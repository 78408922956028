import BotaoSacola from "./BotaoSacola";
import { useEffect, useState } from "react";
import AutoAtendimentoService from "../services/AutoAtendimentoService";
import UpdateService from "../services/UpdateService";

const PageHeader = ({ children, backButtonPress, marginBottom }) => {

    const [mostrarBotaosacola, setShowButton] = useState(false);

    useEffect(() => {

        new UpdateService().validateVersion();

        const sacola = new AutoAtendimentoService().getSacola();

        const pages = ["sacola", "senha"];

        const isNotInPage = !pages.some(page => window.location.pathname.includes(page));

        if (sacola.valorTotal > 0 && isNotInPage)
            setShowButton(true);
        else
            setShowButton(false);

    }, []);

    return (
        <div>
            {mostrarBotaosacola && <BotaoSacola />}
            <div className="bg-dark text-light fixed-top shadow">
                <div className="ms-5">
                    {
                        backButtonPress && <span onClick={backButtonPress} className="float-start mt-3" style={{ fontSize: "4em" }}>
                            <i className="bi bi-arrow-left-circle"></i>
                        </span>
                    }
                    {/* <span onClick={refreshPage} className="float-start mt-3" style={{ fontSize: "4em" }}>
                        <i className="bi bi-arrow-repeat"></i>
                    </span> */}
                    <div className="text-center">
                        {children}
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: marginBottom ?? 140 }} ></div>
        </div>
    )
}

export default PageHeader;