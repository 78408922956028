class SeducaoApi {

    constructor() {

        this.isPrd = window.location.href.includes("sorveteriaseducao");

        this.url = this.isPrd ? "https://api.sorveteriaseducao.com/" : "http://192.168.1.197:5000/";

        this.getToken = () => {
            return this.isPrd
                ? "c7340e96-e00d-4bbe-8035-e5b02fa0be83"
                : "teste-api-local"
        }

    }


    async get(path) {

        let response = await fetch(this.url + path, {

            method: "GET",

            headers: {

                "Content-Type": "application/json",
                "Authentication": this.getToken()
            }

        });
        
        if (response.status === 200) {
            return await response.json();
        }

        return null;
    }

    async post(path, data) {

        let response = await fetch(this.url + path, {

            method: "POST",

            headers: {

                "Content-Type": "application/json",
                "Authentication": this.getToken()
            },

            body: JSON.stringify(data)

        });

        return await response.json();

    }

    async put(path, data) {

        let response = await fetch(this.url + path, {

            method: "PUT",

            headers: {

                "Content-Type": "application/json",
                "Authentication":  this.getToken()

            },

            body: JSON.stringify(data)

        });

        return await response.json();


    }

}

export default SeducaoApi;