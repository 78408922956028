import { useEffect, useState } from "react";
import { AdicionalService, CoberturaService, SaborService, TipoIngrediente } from "../../../services/MontagemService";

import SelecionarIngredientesLoja from "./SelecionarIngredientes";

const MontarPasso = ({ produto, passo, onProximoPasso, onPassoAnterior, onValorAlterado }) => {

    const [parametros, setParametros] = useState(null);

    useEffect(() => {

        switch (passo.tipo) {
            case TipoIngrediente.SABOR:
                setParametros(new SaborService().gerarParametros(produto, passo, onProximoPasso, onValorAlterado));
                break;
            case TipoIngrediente.ADICIONAL:
                setParametros(new AdicionalService().gerarParametros(produto, passo, onProximoPasso, onValorAlterado));
                break;
            case TipoIngrediente.COBERTURA:
                setParametros(new CoberturaService().gerarParametros(produto, passo, onProximoPasso, onValorAlterado));
                break;
            default:
                throw new Error("Tipo de passo não encontrado");
        }

    }, [passo]);

    return parametros && <SelecionarIngredientesLoja onPassoAnterior={onPassoAnterior} passo={parametros} />;

}

export default MontarPasso;