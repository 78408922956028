
export default class UpdateService {

    update() {

        window.location.reload();
    }

    validateVersion() {

        const version = localStorage.getItem("version");

        return;

        if (version === null || version !== process.env.REACT_APP_VERSION) {

            localStorage.setItem("version", process.env.REACT_APP_VERSION);

            this.update();
        }
    }

}