const BotaoImagemDark = (props,) => {

    const { imagem, height } = props;

    return (
        <div className="col-12 btn btn-lg shadow" style={{ height: height, background: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${imagem}) no-repeat center /cover`, display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
            {props.children}
        </div>
    );

}

const BotaoImagemSelected = (props,) => {

    const { imagem, height } = props;

    return (
        <div className="col-12 btn-lg btn text-light shadow" style={{ height: height, background: `linear-gradient( rgba(25,135,84,0.8), rgba(25,135,84,0.8) ), url(${imagem}) no-repeat center /cover`, display: "flex", justifyContent: "space-evenly", flexDirection: "row" }} >
            {props.children}
            <div className="text-center">
                <i style={{ fontSize: "4em" }} className="bi bi-check-circle" ></i>
            </div>
        </div>
    );

}

const IconImage = (props,) => {

    const { imagem, size, tittle } = props;

    return (
        <img height={size} width={size} src={imagem} style={{ borderRadius: 50, objectFit: "none" }} alt={tittle} />
    );

}

export { BotaoImagemDark, BotaoImagemSelected, IconImage };