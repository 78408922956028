import { useNavigate } from "react-router-dom";

const BotaoSacola = (props) => {

    const navigate = useNavigate();

    return (
        <div className="btn btn-warning shadow text-center btn-sacola" onClick={() => navigate("/sacola")} >
            <i style={{ fontSize: "4em" }} className="bi bi-bag"></i>
        </div>
    );
}

export default BotaoSacola;