import { useEffect, useRef, useState } from "react";
import AutoAtendimentoService from "../../services/AutoAtendimentoService";
import SeducaoApi from "../../services/SeducaoApi";
import { useNavigate } from "react-router-dom";
import ToastService from "../../services/ToastService";
import Utils from "../../services/Utils";
import { Modal } from "react-bootstrap";

const PageSacola = () => {

    const navigate = useNavigate();

    const autoAtendimentoService = new AutoAtendimentoService();

    const toastService = new ToastService();

    const seducaoApi = new SeducaoApi();

    const divPedidos = useRef(null);

    const [concluindo, setConcluindo] = useState(false);

    const [sacola, setsacola] = useState(null);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        const sacolaSalvo = autoAtendimentoService.getSacola();

        setsacola(sacolaSalvo);

    }, []);


    const concluirPedido = (paraViagem) => {

        if (concluindo) return;

        setShowModal(false);

        var autoAtendimento = autoAtendimentoService.sacolaToAutoAtendimento(sacola, paraViagem);

        setConcluindo(true);

        seducaoApi.post("autoatendimento", autoAtendimento).then((impressao) => {

            if (impressao.senha) {

                autoAtendimentoService.limparSacola();

                autoAtendimentoService.Imprimir(impressao);

                navigate("/senha/" + impressao.senha);

            } else {

                toastService.error("Não foi possível concluir o pedido. Por favor, informe ao caixa.");

                setConcluindo(false);
            }
        });
    }

    const removerPedido = (id) => {

        autoAtendimentoService.removerPedidoDaSacola(id);

        setsacola(autoAtendimentoService.getSacola());

    }

    const editarPedido = (pedido) => {

        seducaoApi
            .get(`produtos/${pedido.produto.id}/completo`)
            .then(response => {

                response.pedido = pedido;

                autoAtendimentoService.setProdutoSelecionado(response);

                navigate("/montar/" + response.produto.id);
            });

    }

    const duplicarPedido = (pedido) => {

        autoAtendimentoService.duplicarPedido(pedido);

        setsacola(autoAtendimentoService.getSacola());

        toastService.success("Pedido duplicado com sucesso!");

        divPedidos.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }

    return (sacola &&
        <div className="container-fluid">
            {
                sacola.pedidos && sacola.pedidos.length > 0 ?
                    <div>
                        <div ref={divPedidos} >
                            {sacola.pedidos.map((pedido) => {

                                return <div key={pedido.id} className="p-4 shadow border mb-3 div-blink">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="mb-2">
                                                <button onClick={() => removerPedido(pedido.id)} className="btn btn-outline-danger">
                                                    <i className="bi bi-trash-fill" style={{ fontSize: "1.5em", cursor: "pointer" }}></i>
                                                </button>
                                            </div>
                                            <div className="mb-2">
                                                <button onClick={() => editarPedido(pedido)} className="btn btn-outline-dark">
                                                    <i className="bi bi-pencil-fill" style={{ fontSize: "1.5em", cursor: "pointer" }}></i>
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={() => duplicarPedido(pedido)} className="btn btn-outline-primary" style={{ fontSize: "1.5em", cursor: "pointer" }}>
                                                    <i className="bi bi-back"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="ms-2">
                                            <div>
                                                <span style={{ fontSize: "1.4em" }}>
                                                    <span className="text-secondary" style={{ fontSize: "0.9em" }}>
                                                        <span className="text-secondary" style={{ fontSize: "0.6em" }}>
                                                            #{pedido.produto.id}
                                                        </span> {pedido.grupo.nome} |
                                                    </span> {pedido.produto.nome}
                                                </span>
                                            </div>
                                            <div>
                                                {pedido.sabores && pedido.sabores.length > 0 &&
                                                    <span className="text-secondary" style={{ fontSize: "0.9em" }}>
                                                        Sabores: {pedido.sabores.length}
                                                    </span>
                                                }
                                                {pedido.adicionais && pedido.adicionais.length > 0 &&

                                                    <span className="text-secondary" style={{ fontSize: "0.9em" }}>
                                                        {" "}| Adicionais: {pedido.adicionais.length}
                                                    </span>

                                                }
                                                {pedido.coberturas && pedido.coberturas.length > 0 &&

                                                    <span className="text-secondary" style={{ fontSize: "0.9em" }}>
                                                        {" "}| Coberturas: {pedido.coberturas.length}
                                                    </span>

                                                }
                                                <div>
                                                    {pedido.observacao && pedido.observacao.length > 0 &&
                                                        <span className="text-secondary" style={{ fontSize: "0.8em" }}>
                                                            Observação: {pedido.observacao}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <span className="text-danger float-end" style={{ bottom: "0px", fontSize: "2em" }}>{Utils.toCoin(pedido.valorTotal)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                        <div >
                            {
                                concluindo ?
                                    <div className="mt-3">
                                        <button className="btn btn-success btn-lg col-12" type="button" disabled style={{ marginTop: "" }}>
                                            <span style={{ fontSize: "2.5em" }}>
                                                <span style={{ fontSize: "2.5em" }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {" "}
                                                Finalizando...
                                            </span>
                                        </button>
                                    </div>
                                    : <div className="row mt-3">
                                        <div className="col-6">
                                            <button onClick={() => navigate("/")} className="btn btn-outline-secondary btn-lg col-12 mb-3" style={{ marginTop: "" }} >
                                                <span style={{ fontSize: "1.5em" }}>
                                                    <i className="bi bi-plus-circle"></i> Pedir mais
                                                </span>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button onClick={() => setShowModal(true)} className="btn btn-success btn-lg col-12" style={{ marginTop: "" }} >
                                                <span style={{ fontSize: "1.5em" }}>
                                                    <i className="bi bi-check-circle"></i> Finalizar
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "515px" }}>
                        <div className="text-center">
                            <i className="bi bi-cart-x" style={{ fontSize: "10em" }}></i>
                            <h1>Sua sacola está vazia</h1>
                            <button onClick={() => navigate("/")} className="btn btn-outline-secondary btn-lg col-12 mb-3 mt-4" style={{ marginTop: "" }} >
                                <span style={{ fontSize: "2.5em" }}>
                                    <i className="bi bi-plus-circle"></i> Pedir agora
                                </span>
                            </button>
                        </div>
                    </div>
            }

            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} centered={true} >
                <Modal.Body>
                    <div className="p-3">
                        <div>
                            <button onClick={() => concluirPedido(false)} className="btn shadow p-4 btn-success text-light btn-lg col-12" style={{ marginTop: "" }} >
                                <span style={{ fontSize: "2.5em" }}>
                                    <img height={60} color="white" src="/images/icons/iconeColher.svg" alt="Icon" /> Vou comer aqui
                                </span>
                            </button>
                        </div>
                        <div className="mt-3">
                            <button onClick={() => concluirPedido(true)} className="btn shadow p-4 btn-primary btn-lg col-12" style={{ marginTop: "" }} >
                                <span style={{ fontSize: "2.5em" }}>
                                    <i className="bi bi-bag"></i> Embalar para viagem
                                </span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    );
};

export default PageSacola;
