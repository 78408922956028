import ToastService from "./ToastService";
import Utils from "./Utils";

class PosicaoIngrediente {
    static CIMA = 0;
    static MEIO = 1;
    static BAIXO = 2;
    static NENHUM = -1;
}

class TipoIngrediente {
    static SABOR = 1;
    static ADICIONAL = 2;
    static COBERTURA = 3;
}

class IngredienteService {

    toPedidoPasso(passo) {
        return {
            dataHoraInicio: passo.dataHoraInicio,
            dataHoraConclusao: passo.dataHoraConclusao,
            posicao: passo.posicao,
            ordem: passo.ordem,
            titulo: passo.titulo,
            tipo: passo.tipo
        }
    }
}

class AdicionalService extends IngredienteService {

    gerarParametros(produto, passo, onProximoPasso, onValorAlterado) {

        const toastService = new ToastService();

        const algumAdicionalPodeSerGratuito = produto.adicionais
            .map(adicional => {

                const produtoSemAdicionaisSelecionados =
                {
                    produto: {
                        qtdadicionais: produto.produto.qtdadicionais,
                    },
                    pedido: {
                        adicionais: []
                    }
                };

                let ingredienteSimulado = { ...adicional };

                ingredienteSimulado.passo = this.toPedidoPasso(passo);

                ingredienteSimulado.posicao = passo.posicao;

                ingredienteSimulado.precoOriginal = ingredienteSimulado.preco

                const possoZerarEsteAdicional = this.zerarPrecoDesteAdicional(passo, produtoSemAdicionaisSelecionados, ingredienteSimulado);

                return possoZerarEsteAdicional;
            })
            .some(n => n === true);

        return {
            nome: "adicional",
            nomePlural: "adicionais",
            imagemProduto: produto.produto.img,
            posicao: passo.posicao,
            ingredientes: passo.ingredientesExclusivos ?? produto.adicionais,
            mostrarValor: passo.cobrar,
            explicarPosicionamento: passo.posicionando,
            permitirPosicionar: false,
            quantidadeMinima: 0,
            ingredientesSelecionados: produto.pedido.adicionais?.filter(n => n.passo.ordem === passo.ordem),
            getInfoPrecoIngrediente: (ingrediente) => {

                if (this.zerarPrecoDesteAdicional(passo, produto, ingrediente))
                    return <span className="text-light">Grátis</span>;

                return <span className="text-light">+ {Utils.toCoin(ingrediente.preco)}</span>

            },
            titulo: () => {

                if (passo.cobrar && algumAdicionalPodeSerGratuito) {
                    return <div >
                        <h2>{passo.titulo}</h2>
                        <div>
                            Escolha até {passo.quantidadeMaxima} {passo.quantidadeMaxima > 1 ? `adicionais` : `adicional`} gratuitos.
                            <span className="text-danger">* respeitando o limite de porções de cada adicional</span>
                        </div>
                    </div>
                } else {
                    return <div style={{ fontSize: "2.5em" }}>
                        {passo.titulo}
                    </div>
                }
            },
            mensagemCobrancaAposGratuitos: () => {
                return `Cobrado apenas após os ${passo.quantidadeMaxima} gratuítos.`;
            },
            mensagemCobrancaAposLimite: (quantidadeCobrar) => {
                if (quantidadeCobrar > 1)
                    return `Cobrado na ${quantidadeCobrar}ª porção, ou após os gratuítos.`;
            },
            quantidadeMaxima: () => {
                if (passo.cobrar) {
                    if (algumAdicionalPodeSerGratuito)
                        return `Gratuitos: ${produto.pedido.adicionais.filter(n => n.preco === 0).length}/${produto.produto.qtdadicionais}`;
                    else
                        return `Selecionados: ${produto.pedido.adicionais?.filter(n => n.passo.ordem === passo.ordem).length}`;
                }
                return `${produto.pedido.adicionais.length}/${passo.quantidadeMaxima}`;
            },
            removeIngrediente: (ingrediente) => {

                const adicionalRemover = produto.pedido.adicionais.find(n => n.passo.ordem === passo.ordem && n.id === ingrediente.id);

                if (!adicionalRemover) return;

                produto.pedido.adicionais.splice(produto.pedido.adicionais.indexOf(adicionalRemover), 1);

                if (adicionalRemover.preco === 0) {

                    const adicionaisPodemSerGratuitos = produto.pedido.adicionais.filter(n => n.preco > 0 && (n.cobrarAposLimiteDoProduto || n.quantidadeCobrar > 1));

                    if (adicionaisPodemSerGratuitos.length > 0) {

                        const adicionalCobrar = adicionaisPodemSerGratuitos[0];

                        adicionalCobrar.preco = 0;
                    }
                }

                onValorAlterado(produto);

                return produto.pedido.adicionais.filter(n => n.passo.ordem === passo.ordem);

            },
            addIngrediente: (ingrediente) => {

                if (produto.produto.valorfixo > 0) {
                    if (produto.pedido.adicionais.length >= produto.produto.qtdadicionais) {
                        toastService.warning(`Este produto só permite ${produto.produto.qtdadicionais} ${produto.produto.qtdadicionais > 1 ? "adicionais" : "adicional"}.`);
                        return produto.pedido.adicionais.filter(n => n.passo.ordem === passo.ordem);
                    }
                }

                let ingredienteAdicionando = { ...ingrediente };

                ingredienteAdicionando.passo = this.toPedidoPasso(passo);

                ingredienteAdicionando.posicao = passo.posicao;

                ingredienteAdicionando.precoOriginal = ingredienteAdicionando.preco;

                const zerarPreco = this.zerarPrecoDesteAdicional(passo, produto, ingredienteAdicionando);

                if (zerarPreco)
                    ingredienteAdicionando.preco = 0;

                produto.pedido.adicionais.push(ingredienteAdicionando);

                const result = produto.pedido.adicionais.filter(n => n.passo.ordem === passo.ordem);

                onValorAlterado(produto);

                return result;
            },
            onConcluir: (ingredientes) => {

                onProximoPasso(produto);
            }

        };
    }

    zerarPrecoDesteAdicional(passo, produto, ingredienteAdicionando) {

        if (!passo.cobrar)
            return true;

        const adicionaisGratuitos = produto.pedido.adicionais.filter(n => n.preco === 0);

        const dentroDoLimiteDosGratuitos = adicionaisGratuitos.length < produto.produto.qtdadicionais;

        if (!dentroDoLimiteDosGratuitos)
            return false;

        if (ingredienteAdicionando.cobrarAposLimiteDoProduto)
            return dentroDoLimiteDosGratuitos;

        const adicionaisCobrar = [];

        var todosAdicionaisEnvolvidos = [...produto.pedido.adicionais, ingredienteAdicionando];

        let zerar = false;

        todosAdicionaisEnvolvidos.forEach(adicional => {

            let adicionalCobrar = adicionaisCobrar.find(adicionalCobrar => adicionalCobrar.idCobrar === adicional.idCobrar);

            if (adicionalCobrar) {

                adicionalCobrar.qtd++;

            } else {

                adicionalCobrar = {
                    idCobrar: adicional.idCobrar,
                    qtd: 1
                }

                adicionaisCobrar.push(adicionalCobrar);
            }

            if (adicionalCobrar.qtd < adicional.quantidadeCobrar) {
                zerar = (adicional.id === ingredienteAdicionando.id && ingredienteAdicionando.ordem === adicional.ordem);
            }
            else {
                zerar = false;
            }

        });

        return zerar;
    }
}

class CoberturaService extends IngredienteService {

    gerarParametros(produto, passo, onProximoPasso, onValorAlterado) {
        return {

            nome: "cobertura",
            nomePlural: "coberturas",
            pedido: produto.pedido,
            mostrarValor: passo.cobrar,
            explicarPosicionamento: passo.posicionando,
            posicao: passo.posicao,
            titulo: () => {
                return <div style={{ fontSize: "2.5em" }}>
                    {passo.titulo}
                </div>
            },
            ingredientes: passo.ingredientesExclusivos ?? produto.coberturas,
            ingredientesSelecionados: produto.pedido.coberturas.filter(n => n.passo.ordem === passo.ordem),
            quantidadeMinima: 0,
            quantidadeMaxima: () => { return `${produto.pedido.coberturas.length}/${produto.produto.qtdcoberturas}` },
            getInfoPrecoIngrediente: (ingrediente) => {
                if (ingrediente.preco === 0)
                    return null;

                return <span className="text-light">+ {Utils.toCoin(ingrediente.preco)}</span>
            },
            addIngrediente: (ingrediente) => {

                ingrediente.passo = this.toPedidoPasso(passo);
                ingrediente.posicao = passo.posicao;

                if (produto.produto.qtdcoberturas === 1)
                    produto.pedido.coberturas = [];

                if (produto.pedido.coberturas.length >= produto.produto.qtdcoberturas) {
                    new ToastService().warning("Você já escolheu o máximo de coberturas permitidas");
                    return produto.pedido.coberturas;
                }

                produto.pedido.coberturas.push(ingrediente);

                return produto.pedido.coberturas.filter(n => n.passo.ordem === passo.ordem);
            },
            removeIngrediente: (ingrediente) => {

                let index = produto.pedido.coberturas.findIndex(n => n.passo.ordem === passo.ordem && n.id === ingrediente.id);

                if (index > -1) {
                    produto.pedido.coberturas.splice(index, 1);
                }

                return produto.pedido.coberturas.filter(n => n.passo.ordem === passo.ordem);

            },
            onConcluir: (ingredientes) => {

                produto.pedido.coberturas = ingredientes;

                onProximoPasso(produto);
            }

        };
    }

}

class SaborService extends IngredienteService {

    gerarParametros(produto, passo, onProximoPasso, onValorAlterado) {

        const toastService = new ToastService();

        return {
            nome: "sabor",
            nomePlural: "sabores",
            mostrarValor: passo.cobrar,
            explicarPosicionamento: passo.posicionando,
            titulo: () => {
                return <div style={{ fontSize: "2.5em" }}>
                    {passo.titulo}
                </div>
            },
            ingredientes: passo.ingredientesExclusivos ?? produto.sabores,
            ingredientesSelecionados: produto.pedido.sabores.filter(n => n.passo.ordem === passo.ordem),
            quantidadeMaxima: () => { return `${produto.pedido.sabores.filter(n => n.passo.ordem === passo.ordem).length}/${passo.quantidadeMaxima}` },
            addIngrediente: (ingrediente) => {

                ingrediente.passo = this.toPedidoPasso(passo);

                ingrediente.posicao = passo.posicao;

                var saboresDestaOrdem = produto.pedido.sabores.filter(n => n.passo.ordem === passo.ordem);

                if (passo.quantidadeMaxima === 1) {

                    produto.pedido.sabores = produto.pedido.sabores.filter(n => n.passo.ordem !== passo.ordem);

                } else if (saboresDestaOrdem.length >= passo.quantidadeMaxima) {
                    toastService.warning("Você já escolheu o máximo de sabores permitidos");
                    return produto.pedido.sabores;
                }

                produto.pedido.sabores.push(ingrediente);

                return produto.pedido.sabores.filter(n => n.passo.ordem === passo.ordem);
            },
            removeIngrediente: (ingrediente) => {

                let index = produto.pedido.sabores.findIndex(n => n.passo.ordem === passo.ordem && n.id === ingrediente.id);

                if (index > -1) {
                    produto.pedido.sabores.splice(index, 1);
                }

                return produto.pedido.sabores.filter(n => n.passo.ordem === passo.ordem);

            },
            getInfoPrecoIngrediente: (ingrediente) => {
                if (ingrediente.preco === 0)
                    return null;

                return <span className="text-light">+ {Utils.toCoin(ingrediente.preco)}</span>
            },
            onChange: (ingredientes) => { }, // Não faz nada
            onConcluir: (ingredientes) => {

                if (ingredientes.length === 0) {

                    toastService.warning(`Você precisa selecionar 1 sabor`);
                    return;
                }
                onProximoPasso(produto);
            }

        };
    }

}

export { AdicionalService, CoberturaService, SaborService, TipoIngrediente, PosicaoIngrediente };