import moment from "moment";
import AutoAtendimentoImpressaoService from "./AutoAtendimentoImpressaoService";

class AutoAtendimentoService {

    sacolaToAutoAtendimento(sacola, paraViagem) {

        const produtos = [];

        let valorTotal = 0;

        sacola.pedidos.forEach((pedidoNaSacola) => {

            var produtoAutoAtendimento = {
                ...pedidoNaSacola.produto,
                observacao: pedidoNaSacola.observacao,
                valorTotal: pedidoNaSacola.valorTotal,
                valorIngredientes: pedidoNaSacola.valorIngredientes
            };

            let passosDoAdicionais = this.converterParaPassos(pedidoNaSacola, pedidoNaSacola.adicionais);
            let passosDoSabores = this.converterParaPassos(pedidoNaSacola, pedidoNaSacola.sabores);
            let passosDoCoberturas = this.converterParaPassos(pedidoNaSacola, pedidoNaSacola.coberturas);

            produtoAutoAtendimento.passos = [...passosDoAdicionais, ...passosDoSabores, ...passosDoCoberturas];

            produtos.push(produtoAutoAtendimento);

            valorTotal += pedidoNaSacola.valorTotal;
        });

        return {
            valorTotal: valorTotal,
            paraViagem: paraViagem,
            produtos: produtos
        };
    }

    removerPedidoDaSacola(id) {

        let sacola = this.getSacola();

        sacola.pedidos = sacola.pedidos.filter((pedido) => pedido.id !== id);

        sacola.valorTotal = sacola.pedidos.reduce((total, pedido) => total + pedido.valorTotal, 0);

        this.setSacola(sacola);
    }

    adicionarPedidoNaSacola(pedido) {

        let sacola = this.getSacola();

        if (!pedido.id)
            pedido.id = new Date().getTime();

        if (sacola.produtos) {

            sacola.pedidos = sacola.produtos.filter((pedidoSacola) => pedidoSacola.id !== pedido.id);

            delete sacola.produtos;
        }

        sacola.pedidos = sacola.pedidos.filter((pedidoSacola) => pedidoSacola.id !== pedido.id);

        sacola.pedidos.push(pedido);

        sacola.valorTotal = sacola.pedidos.reduce((total, pedido) => total + pedido.valorTotal, 0);

        this.setSacola(sacola);
    }

    duplicarPedido(pedido) {

        let novoPedido = { ...pedido }

        novoPedido.id = undefined;

        this.adicionarPedidoNaSacola(novoPedido);
    }

    criarPedido(produtoSelecionado) {
        return {
            valorTotal: produtoSelecionado.produto.preco,
            produto: produtoSelecionado.produto,
            grupo: produtoSelecionado.grupo,
            sabores: [],
            adicionais: [],
            coberturas: []
        }
    }

    getSacola() {

        let sacola = localStorage.getItem("sacola");

        if (sacola) {

            return JSON.parse(sacola);

        } else {

            return {

                pedidos: []

            };
        }
    }

    setSacola(sacola) {

        localStorage.setItem("sacola", JSON.stringify(sacola));

    }

    limparSacola() {

        localStorage.removeItem("sacola");

    }

    converterParaPassos(pedido, ingredientes) {
        let passos = [];

        ingredientes.forEach((ingrediente) => {

            let passo = passos.find((passo) => passo.ordem === ingrediente.passo.ordem);

            if (passo) {

                passo.ingredientes.push(this.toAtendimentoPassoIngrediente(ingrediente));

            } else {

                passo = this.toAtendimentoPasso(pedido.passos.find(n => n.ordem === ingrediente.passo.ordem));

                passo.ingredientes.push(this.toAtendimentoPassoIngrediente(ingrediente));

                passos.push(passo);
            }

        });

        return passos;
    }

    toAtendimentoPasso(passo) {

        return {
            descricao: passo.titulo,
            dataHoraInicio: moment(passo.dataHoraInicio).format("YYYY-MM-DDTHH:mm:ss"),
            dataHoraConclusao: moment(passo.dataHoraConclusao).format("YYYY-MM-DDTHH:mm:ss"),
            posicao: passo.posicao,
            tipo: passo.tipo,
            ordem: passo.ordem,
            ingredientes: []
        };
    }

    toAtendimentoPassoIngrediente(ingrediente) {

        return {
            id: ingrediente.id,
            nome: ingrediente.nome,
            preco: ingrediente.preco
        };
    }

    setProdutoSelecionado(produto) {

        localStorage.setItem("produtoSelecionado", JSON.stringify(produto));
    }

    getProdutoSelecionado() {

        return JSON.parse(localStorage.getItem("produtoSelecionado"));

    }

    Imprimir(autoAtendimento) {
        new AutoAtendimentoImpressaoService().Imprimir(autoAtendimento);
    }

    fraseComemorativaAleatoria() {

        const palavrasParaComemorar =
            [
                "Que maravilha", "Vai ficar incrível", "Vai ficar ótimo", "Vai ficar uma delícia", "Vai ficar delicioso",
                "Vai ficar maravilhoso", "Uhuuull, isso vai ficar muito bom", "Que delícia", "Que pedido magnífico", "Pedido fenomenal",
                "Um pedido fantástico", "Excelente pedido", "Esse pedido foi surpreendente", "Um pedido sensasional", "Bom demais esse pedido",
                "Esplêndido seu pedido", "Espetacular esse pedido", "Um pedido estupendo", "Pedido extraordinário", "Um fabuloso pedido", "Um pedido fabuloso",
                "Deslumbrante esse pedido", "Vai ficar gostosíssimo", "Primoroso seu pedido", "Saboroso demais esse pedido", "Perfeito esse pedido",
                "Seu pedido ta supimpa", "Sensacional esse seu pedido", "Formidável seu pedido", "Um pedido impecável", "Seu pedido foi excepcional",
                "Um pedido excepcional", "Um pedido espetacular", "Um pedido esplêndido", "Um pedido formidável", "Um pedido primoroso",
                "Um pedido sensacional", "Um pedido supimpa", "Um pedido surpreendente", "Um pedido extraordinário", "Um pedido fantástico",
                "Um pedido magnífico", "Um pedido fenomenal", "Um pedido deslumbrante",
            ]

        let index = Math.floor(Math.random() * palavrasParaComemorar.length)

        return palavrasParaComemorar[index];
    }
}

export default AutoAtendimentoService;  
