import { BrowserRouter, Routes, Route } from "react-router-dom"
import PageGrupos from "./pages/loja/PageGrupos";
import PageHeaderLoja from "./components/PageHeaderLoja";
import PageGrupo from "./pages/loja/PageGrupo";
import PageMontar from "./pages/loja/PageMontar";
import PageSacola from "./pages/loja/PageSacola";

function RoutesLoja() {

    return (
        <BrowserRouter>
            <PageHeaderLoja />
            <Routes>
                <Route path="/" element={<PageGrupos />} />
                <Route exact path="/grupo/:id" element={<PageGrupo />} />
                <Route exact path="/montar/:id" element={<PageMontar />} />
                <Route exact path="/sacola" element={<PageSacola />} />
            </Routes>
        </BrowserRouter >
    )
}

export default RoutesLoja;