import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SeducaoApi from "../../services/SeducaoApi";
import Utils from "../../services/Utils";
import PageHeader from "../../components/PageHeader";

const PageProduto = params => {

    let loaded = false;

    const navigate = useNavigate();

    const { id } = useParams();

    const [produto, setProduto] = useState(null)

    const seducaoApi = new SeducaoApi();

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        carregarProduto();

    }, []);

    const carregarProduto = async () => {

        seducaoApi.get(`produtos/${id}/completo`).then(produto => {

            setProduto(produto);
        })
    }
    return (
        produto ? (
            <div className="container-fluid">
                <PageHeader>
                    <div className="d-flex">
                        <div className="p-0">
                            <span onClick={() => navigate(`/grupo/${produto.grupo.id}`)} className="float-start" style={{ fontSize: "4em" }}><i className="bi bi-arrow-left-circle"></i></span>
                        </div>
                        <div className="p-2">
                        </div>
                        <div className="p-2 flex-grow-1">
                            <div className="text-secondary" style={{ fontSize: "1.2em" }}>
                                {produto.grupo.nome}
                                <span>
                                    {` #${produto.produto.id}`}
                                </span>
                            </div>
                            <span style={{ fontSize: "2.5em" }}>{produto.produto.nome}</span>
                        </div>
                        <div className="p-2 bg-warning">
                            <span className="text-dark " style={{ fontSize: "4.5em" }}>{Utils.toCoin(produto.produto.preco)}</span>
                        </div>
                    </div>
                </PageHeader>
                <div className="row">
                    <div className="col-5">
                        <div className="col-12 shadow" style={{ height: "600px", background: `linear-gradient( rgba(0, 0, 0, 0.0), rgba(30, 0, 0, 0.0) ), url(${produto.produto.img}) no-repeat center /cover` }}>

                        </div>
                    </div>
                    <div className="col-7" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                        <div className="text-center" style={{ fontSize: "3em" }} >
                            {produto.produto.descricao}
                        </div>
                        <button onClick={() => navigate(`/montar/${id}`)} className="btn btn-success btn-lg col-12" style={{ marginTop: "auto" }} >
                            <span style={{ fontSize: "3em" }}>Selecionar <i className="bi bi-check-lg"></i></span>
                        </button>
                    </div>

                </div>
            </div >
        ) : null
    )

}

export default PageProduto; 