import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SeducaoApi from "../../services/SeducaoApi";
import Utils from "../../services/Utils";
import ConcluirMontagem from "../../components/montagem/loja/ConcluirMontagem";
import MontarPassoLoja from "../../components/montagem/loja/MontarPasso";
import AutoAtendimentoService from "../../services/AutoAtendimentoService";
import { PosicaoIngrediente } from "../../services/MontagemService";

const PageMontar = params => {

    const seducaoApi = new SeducaoApi();

    const autoAtendimentoService = new AutoAtendimentoService();

    const { id } = useParams();

    let loaded = false;

    const navigate = useNavigate();

    const [passoAtual, setPassoAtual] = useState(null);

    const [concluir, setConcluir] = useState(false);

    const [totalPedido, setTotalPedido] = useState(0);

    const [produtoSelecionado, setProdutoSelecionado] = useState(null);

    const [grupoSelecionado, setGrupoSelecionado] = useState(null);

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        carregarProduto();

    }, [id]);

    const carregarProduto = async () => {

        const produtoSelecionado = autoAtendimentoService.getProdutoSelecionado();

        if (produtoSelecionado && produtoSelecionado.produto.id === parseInt(id)) {

            iniciarMontagem(produtoSelecionado);

            valorAlterado(produtoSelecionado);

        } else {


            seducaoApi.get(`produtos/${id}/completo`).then(produtoCompleto => {

                produtoCompleto.pedido = autoAtendimentoService.criarPedido(produtoCompleto);

                autoAtendimentoService.setProdutoSelecionado(produtoCompleto);

                iniciarMontagem(produtoCompleto);
            })
        }
    }

    const iniciarMontagem = (produto) => {

        setProdutoSelecionado(produto);

        setGrupoSelecionado(produto.grupo);

        setTotalPedido(produto.produto.preco);

        irParaProximoPasso(produto);
    }

    const getProximoPasso = (produto) => {

        if (passoAtual === null)
            return produto.passos.find(passo => passo.ordem === 1);

        const passos = produto.passos;

        passos.sort((a, b) => a.ordem - b.ordem);

        const passo = passos.find(passo => passo.posicao !== PosicaoIngrediente.BAIXO && passo.ordem > passoAtual.ordem);

        return passo;
    }

    const getPassoAnterior = () => {

        const passos = produtoSelecionado.passos;

        passos.sort((a, b) => b.ordem - a.ordem);

        const passo = passos.find(passo => passo.posicao !== PosicaoIngrediente.BAIXO && passo.ordem < passoAtual.ordem);

        return passo;

    }

    const irParaProximoPasso = (produtoContexto) => {

        setProdutoSelecionado(produtoContexto);

        if (passoAtual)
            passoAtual.dataHoraConclusao = Utils.getDataHoraAtual();

        var proximoPasso = getProximoPasso(produtoContexto);

        if (proximoPasso) {

            proximoPasso.dataHoraInicio = Utils.getDataHoraAtual();

            setPassoAtual(proximoPasso);

        } else {

            setConcluir(true);
        }

        produtoContexto.pedido.passos = produtoContexto.passos;

        autoAtendimentoService.setProdutoSelecionado(produtoContexto);

    }

    const voltarParaPassoAnterior = () => {

        var passoAnterior = getPassoAnterior();

        if (passoAnterior) {
            if (concluir) {
                setConcluir(false);
                return;
            }
            setPassoAtual(passoAnterior);
        }
        else
            navigate(`/grupo/${grupoSelecionado.id}`);
    }

    const valorAlterado = (novoProduto) => {

        let totalAdicionais = 0;
        let totalSabores = 0;
        let totalCobertura = 0;

        novoProduto.pedido.adicionais.forEach((adicional) => { totalAdicionais += adicional.preco; });
        novoProduto.pedido.sabores.forEach((sabor) => { totalSabores += sabor.preco; });
        novoProduto.pedido.coberturas.forEach((cobertura) => { totalCobertura += cobertura.preco; });

        novoProduto.pedido.valorIngredientes = totalAdicionais + totalSabores + totalCobertura;
        novoProduto.pedido.valorTotal = novoProduto.produto.preco + novoProduto.pedido.valorIngredientes;

        setTotalPedido(novoProduto.pedido.valorTotal);

        setProdutoSelecionado(novoProduto);

        autoAtendimentoService.setProdutoSelecionado(novoProduto);
    }


    return (
        produtoSelecionado ? (
            <div className="container mb-2">
                <div className="d-flex">
                    <div className="p-2 flex-grow-1">
                        <div className="text-secondary" style={{ fontSize: "1em" }}>
                            {produtoSelecionado.grupo.nome}
                            <span>
                                {` #${produtoSelecionado.produto.id}`}
                            </span>
                        </div>
                        <span style={{ fontSize: "1em" }}>{produtoSelecionado.produto.nome}</span>
                    </div>
                    <div className="p-2">
                        <span className="text-dark " style={{ fontSize: "2.5em" }}>{Utils.toCoin(totalPedido)}</span>
                    </div>
                </div>
                {
                    concluir
                        ? <ConcluirMontagem produtoCompleto={produtoSelecionado} onPassoClick={(passo) => { setConcluir(false); setPassoAtual(passo) }} />
                        : <MontarPassoLoja passo={passoAtual} produto={produtoSelecionado} onPassoAnterior={voltarParaPassoAnterior} onProximoPasso={irParaProximoPasso} onValorAlterado={valorAlterado} />
                }

            </div >
        ) : null
    )

}

export default PageMontar; 