import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import AutoAtendimentoImpressaoService from "../../services/AutoAtendimentoImpressaoService";

const PageImpressao = params => {

    const impressaoService = new AutoAtendimentoImpressaoService();
    const [senha, setSenha] = useState("");

    const [textoGerado, setTextoGerado] = useState("");

    const imprimir = () => {

        impressaoService.CarregarImprimirBySenhaDeHoje(senha);
    }

    const simular = () => {

        impressaoService.GerarImpressao(senha, async (impressao) => {

            setTextoGerado(impressao);
        })
    }

    return (
        <div>
            <PageHeader>
                <div className="p-4">
                    <h1>Impressão</h1>
                </div>
            </PageHeader>
            <div className="container">
                <div className="mb-3">
                    <label style={{ fontSize: "2em" }} className="form-label">Senha</label>
                    <input value={senha} onChange={(e) => setSenha(e.target.value)} type="number" className="form-control form-control-lg" style={{ fontSize: "3em" }} />
                </div>
                <div className="mb-3">
                    <button onClick={imprimir} className="btn mb-3 btn-lg btn-success col-12" style={{ fontSize: "3em" }} >
                        Imprimir
                        <i className="bi bi-printer-fill ms-2"></i>
                    </button>
                    <button onClick={simular} className="btn btn-lg btn-info col-12" style={{ fontSize: "3em" }} >
                        Simular
                        <i className="bi bi-printer-fill ms-2"></i>
                    </button>
                </div>
                <code>
                    {
                        textoGerado ? <pre className="pos-print"> {textoGerado}</pre> : ""
                    }
                </code>
            </div>
        </div>
    );

}

export default PageImpressao; 