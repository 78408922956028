import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SeducaoApi from "../../services/SeducaoApi";
import PageHeader from "../../components/PageHeader";
import { BotaoImagemDark } from "../../components/botoes/BotaoImagem";
import Span from "../../Span";
import ToastService from "../../services/ToastService";
import AutoAtendimentoService from "../../services/AutoAtendimentoService";

const PageGrupos = params => {

    let loaded = false;

    const [grupos, setGrupos] = useState([])

    const navigate = useNavigate();

    const seducaoApi = new SeducaoApi();

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        seducaoApi.get("grupos").then(response => {

            response.sort((a, b) => a.ordem - b.ordem);

            setGrupos(response);
        })

    }, []);

    const gerarPedidoVazio = () => {

        seducaoApi.post("autoatendimento/vazio").then(impressao => {

            if (impressao.senha) {

                const autoAtendimentoService = new AutoAtendimentoService();

                autoAtendimentoService.Imprimir(impressao);

                navigate("/senha/" + impressao.senha);

            } else {

                new ToastService().error("Não foi possível concluir o pedido. Por favor, informe ao caixa.");
            }

        })
    }

    const selecionarGrupo = (grupo) => {

        if (grupo.horaLimiteParaPedidos) {

            const horaLimiteParaPedidos = new Date(grupo.horaLimiteParaPedidos);

            const horaLimite = horaLimiteParaPedidos.getHours();

            const minutoLimite = horaLimiteParaPedidos.getMinutes();

            const agora = new Date();

            const minutoAgora = agora.getMinutes();

            const horaAgora = agora.getHours();

            if (horaAgora > horaLimite || (horaAgora === horaLimite && minutoAgora > minutoLimite)) {

                const horaFormatada = horaLimite < 10 ? "0" + horaLimite : horaLimite;
                const minutoFormatado = minutoLimite < 10 ? "0" + minutoLimite : minutoLimite;

                new ToastService().warning(`Não é possível fazer pedidos de ${grupo.nome} após as ${horaFormatada}:${minutoFormatado}h`);

                return;
            }

        }

        navigate(`grupo/${grupo.id}`);


    }

    return (
        <div className="container-fluid">
            <PageHeader>
                <div className="ms-5 p-3">
                    <button onClick={gerarPedidoVazio} className="float-end btn btn-primary btn-lg mt-1">
                        <div className="row">
                            <div className="col-2" style={{ fontSize: "2.2em" }}>
                                <i className="bi bi-pass"></i>
                            </div>
                            <div className="col-10" >
                                Não quero pedir por aqui,<br></br> só quero uma senha
                            </div>
                        </div>
                    </button>
                    <div className="text-center">
                        <Span size={4}>Faça seu pedido aqui</Span>
                    </div>
                </div>
            </PageHeader>
            <div className="row">
                {grupos.map(grupo => {
                    return (
                        <div onClick={() => selecionarGrupo(grupo)} key={grupo.id} className="text-light mb-2 col-4">
                            <BotaoImagemDark height={150} imagem={grupo.img} >
                                <span className="text-light" style={{ fontSize: "2.1em" }}>{grupo.nome}</span>
                            </BotaoImagemDark>
                        </div>
                    )
                })}
            </div>
        </div >
    )

}

export default PageGrupos; 