import { BrowserRouter, Routes, Route } from "react-router-dom"
import PageGrupos from "./pages/balcao/PageGrupos";
import PageProduto from "./pages/balcao/PageProduto";
import PageMontar from "./pages/balcao/PageMontar";
import PageSenha from "./pages/balcao/PageSenha";
import PageImpressao from "./pages/balcao/PageImpressao";
import PageSacola from "./pages/balcao/PageSacola";
import PageGrupo from "./pages/balcao/PageGrupo";

function RoutesBalcao() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageGrupos />} />
                <Route exact path="/senha/:senha" element={<PageSenha />} />
                <Route exact path="/grupo/:id" element={<PageGrupo />} />
                <Route exact path="/produto/:id" element={<PageProduto />} />
                <Route exact path="/montar/:id" element={<PageMontar />} />
                <Route exact path="/sacola" element={<PageSacola />} />
                <Route exact path="/impressao" element={<PageImpressao />} />
            </Routes>
        </BrowserRouter >
    )
}

export default RoutesBalcao;