import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import Configuracao from '../../Configuracao';

export default class ToastService {

    show(message, style) {

        const fontSize = Configuracao.isBalcao ? "2.5em" : "1.5em";

        Toastify({
            text: message,
            duration: 3000,
            gravity: "top", // `top` or `bottom`
            position: "left", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                ...style,
                fontSize: fontSize,
                boxShadow: "6px 5px 21px -9px rgba(0,0,0,0.75)",
                padding: "20px",
                zIndex: 9999,
                position: "fixed"
            }
        }).showToast();
    }

    success(message) {
        this.show(message, {
            color: "#fff",
            background: "linear-gradient(81deg, rgba(0,218,91,1) 0%, rgba(0,177,74,1) 100%)"
        });
    }

    error(message) {
        this.show(message, {
            color: "#fff",
            background: "linear-gradient(81deg, rgba(247,40,37,1) 0%, rgba(244,86,86,1) 100%)"
        });
    }

    warning(message) {
        this.show(message, {
            color: "#000",
            background: "linear-gradient(81deg, rgba(255,214,43,1) 0%, rgba(255,164,26,1) 100%)"
        });
    }

    info(message) {
        this.show(message, {
            color: "#fff",
            background: "linear-gradient(81deg, rgba(60,43,255,1) 0%, rgba(26,157,255,1) 100%)",
        });
    }

    all(message) {

        this.success(message);
        this.error(message);
        this.warning(message);
        this.info(message);

    }
}